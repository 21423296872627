@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.animate-float {
  animation: float 8s ease-in-out infinite;
}

@keyframes electrify {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes electrify {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.electrifying-border {
  position: absolute;
  inset: -8px; /* Increased from -2px to -4px for a thicker border */
  background: linear-gradient(
    45deg,
    #ff8a00,
    #e52e71,
    #2196f3,
    #ff8a00
  );
  background-size: 300% 300%;
  border-radius: 12px; /* Increased to match the thicker border */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 0;
}

.group:hover .electrifying-border {
  opacity: 1;
  animation: electrify 3s ease infinite;
}

.service-content {
  position: relative;
  background: white;
  border-radius: 8px;
  z-index: 1;
  margin: 4px; /* Added to compensate for the thicker border */
}